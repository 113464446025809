<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <div class="search_bar">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="filter.dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateRangeText"
            placeholder="Заезд - выезд"
            prepend-inner-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            height="48"
            v-on="on"
            hide-details
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.dates"
          :first-day-of-week="1"
          no-title
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Отмена </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(filter.dates)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-autocomplete
        v-model="filter.city"
        :items="cities"
        placeholder="Местность"
        item-text="name"
        item-value="slug"
        height="44"
        hide-details
        outlined
        dense
        @change="handleChangeCity"
      >
      </v-autocomplete>
      <v-autocomplete
        v-model="filter.hotel"
        :items="hotels"
        placeholder="Отель"
        item-text="name"
        item-value="id"
        height="44"
        hide-details
        outlined
        dense
        @change="getCategories"
      >
      </v-autocomplete>
      <v-btn height="44" width="160" color="primary" dense @click="search">
        Поиск
      </v-btn>
    </div>
    <today-conditions :info="stat_info" />
    <p class="sales_title mr-6" v-text="hotels.find(e => e.id == filter.hotel) ? hotels.find(e => e.id == filter.hotel).name : ''"></p>
    <v-btn @click="toggle(-1)" icon>
      <v-icon color="primary">mdi-arrow-left-thick</v-icon>
    </v-btn>
    <v-btn @click="toggle(1)" icon>
      <v-icon color="primary">mdi-arrow-right-thick</v-icon>
    </v-btn>
    <div class="calendar_wrapper">
      <div class="calendar_wrapper__header">
        <div class="calendar_wrapper__header__title">
          <p>Комнаты</p>
        </div>
        <div class="calendar_wrapper__header__days" id="style-1">
          <div class="calendar_wrapper__header__days__month">
            <p v-text="months"></p>
          </div>
          <div class="header_dates">
            <div
              v-for="(date, idx) in dates"
              :key="idx"
              class="single_box"
              :class="{ isWeekEnd: date.isWeekEnd }"
              :style="{ 'min-width': `${date.width}` }"
            >
              <div v-html="date.text"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="calendar_body">
        <v-expansion-panels v-model="panels" multiple accordion>
          <v-expansion-panel v-for="(i, idx) in steady" :key="i.id" readonly>
            <v-expansion-panel-header
              @click="onToggle(idx)"
              class="panel_header pa-0"
              hide-actions
            >
              <div class="room_headers">
                <div class="room_headers__title room_headers_title__cat">
                  <p v-text="i.name"></p>
                </div>
                <div class="header_dates">
                  <div
                    v-for="(date, idx) in dates"
                    :key="idx"
                    :style="{ width: `${date.width}` }"
                    class="single_box"
                  ></div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="panel_content">
              <div v-for="j in i.room_list" :key="j.id" class="room_headers">
                <div class="room_headers__title">
                  <p v-text="j.name"></p>
                </div>
                <div class="header_dates" style="position: relative">
                  <div
                    v-for="(date, idx) in dates"
                    :key="idx"
                    :class="{ isWeekEnd: date.isWeekEnd }"
                    class="single_box"
                    :style="{ width: date.width }"
                  >
                  </div>
                  <div @click="openBooking(k, i, j)" v-for="(k) in j.booking_list" :key="k.id" class="single_box single_box_booking" :style="k.style">
                    <div>
                      <span v-text="k.client ? k.client.full_name : 'Не заполнено!'"></span>  
                      <span v-text="k.source"></span>  
                    </div>
                    <span style="text-align:right" v-text="k.total_price ? k.total_price.toLocaleString('KZ-kz') + 'тг' : ''"></span>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <v-dialog v-model="bookingModal" width="886" @click:outside='bookingModal = false' persistent>
      <div class="dialog_wrapper">
          <p class="dialog_wrapper__title">Детали бронирования</p>
          <div>
            <v-row>
            <v-col cols="6">
              <v-text-field v-model="bookingItem.id" disabled label="Номер заявки" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bookingItem.tour_agent" disabled label="Турагент" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="bookingItem.client ? bookingItem.client.full_name : 'Не заполнено!'" disabled label="ФИО Туриста" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
                <v-text-field :value="bookingItem.category ? bookingItem.category.name : ''" disabled label="Категория номера" >
                </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bookingItem.date" disabled label="Период заселения" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="bookingItem.room"
                :items="bookingItem.room_list"
                placeholder="Номер комнаты"
                item-text="name"
                item-value="id"
                height="44"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bookingItem.days" disabled label="Количество ночей" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field :value="bookingItem.child_count + bookingItem.adult_count" disabled label="Доп. место" >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="bookingItem.total_price" disabled label="Стоимость всего" >
              </v-text-field>
            </v-col>
            </v-row>
          </div>
          <div class="button_wrapper">
            <v-btn outlined width="245" class="mr-3" height="48" color="primary" @click="bookingModal = false">Закрыть</v-btn>
            <v-btn outlined width="245" height="48" color="primary" @click="saveBooking">Сохранить</v-btn>
          </div>
      </div>
  </v-dialog>
  </div>
</template>

<script>
import vueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import TodayConditions from "@/components/ui/todayConditions.vue";
import { dynamicService } from "@/services/salesDynamic.service.js";
import { accommodationService } from "@/services/accommodation.service.js";

export default {
  components: {
    vueBreadcrumbs,
    TodayConditions,
  },
  data: () => ({
    bookingItem: {
    },
    bookingModal: false, 
    panels: [],
    dates: [],
    menu: false,
    filter: {
      dates: [],
    },
    cities: [],
    hotels: [],
    months: '', 
    width: '',
    breadCrumbs: [
      {
        text: "Динамика продаж блоков",
        to: "/administration/sales",
      },
      {
        text: "Проживание",
        to: "/administration/sales/residence",
      },
    ],
    stat_info: {},
    steady: [],
    statuses: {
      reserved: 'Резерв',
      booked: 'Бронь',
      check_in: 'Заселен',
      check_out: 'Выселен',
      block: 'Блок',
      koika: 'Койка',
      time: 'Часовая',
      canceled: 'Отменен'
    },
    categories: []
  }),
  computed: {
    dateRangeText() {
      if (this.filter.dates.length == 2) {
        if (this.filter.dates[0] > this.filter.dates[1])
          this.filter.dates.reverse();
      }
      return this.filter.dates.join(" ~ ");
    },
  },
  created() {
    this.getCities();
  },
  methods: {
    async search() {
      this.$loading(true)
      await this.getStatistics();
      // await this.getCalendar();
      this.$loading(false)
    },
    async getCalendar() {
      if (!this.filter.hotel) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Выберите отель!",
          color: "error",
        });
        return;
      }
      if (this.filter.dates.length < 2) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Выберите время!",
          color: "error",
        });
        return;
      }
      let res = await dynamicService.getCalendar({
        company_id: this.filter.hotel,
        start: this.filter.dates[0],
        end: this.filter.dates[1],
      });
      this.steady = res;
      this.setDays();
      this.setBookings()
    },
    async getStatistics() {
      if (!this.filter.hotel) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Выберите отель!",
          color: "error",
        });
        return;
      }
      let res = await dynamicService.getStatistics({
        company_id: this.filter.hotel,
      });
      this.stat_info = res;
    },
    async handleChangeCity() {
      let res = await dynamicService.getHotels({
        city: this.filter.city,
      });
      this.hotels = res.results;
    },
    async getCities() {
      let res = await accommodationService.getCities();
      this.cities = res;
    },
    async getCategories(){
      let res = await dynamicService.getCategories({
        company_id: this.filter.hotel
      })
      this.categories = res
    },
    toggle(numb){
      this.filter.dates = [
        this.$moment(new Date(this.filter.dates[0])).add(numb, "days").format("YYYY-MM-DD"),
        this.$moment(new Date(this.filter.dates[1])).add(numb, "days").format("YYYY-MM-DD"),
      ]
      this.search()
    },
    setDays() {
      this.dates = [];
      let dates = [ new Date(this.filter.dates[0]), new Date(this.filter.dates[1]) ]
      const diffTime = Math.abs(dates[1] - dates[0]);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; 
      this.width = ( 61 / diffDays ) + 'vw'
      if(dates[0].getMonth() != dates[1].getMonth()){
        this.months = `${this.$moment(new Date(this.filter.dates[0])).format("MMMM YYYY")} - ${this.$moment(new Date(this.filter.dates[1])).format("MMMM YYYY")}` 
      }
      else this.months = `${this.$moment(new Date(this.filter.dates[0])).format("MMMM YYYY")}` 
      for (let i = 0; i < diffDays; i++) {
        this.dates.push({
          text: this.$moment(new Date(this.filter.dates[0])).add(i, "days").format("DD <br/> dd"),
          isWeekEnd:
            this.$moment(new Date(this.filter.dates[0])).add(i, "days").day() == 5 ||
            this.$moment(new Date(this.filter.dates[0])).add(i, "days").day() == 6,
          width: this.width
        });
      }
    },
    setBookings(){
      this.steady.forEach(e => {
        e.room_list.forEach(j => {
          j.booking_list = j.booking_list.concat(j.block_list)
          j.booking_list.forEach(k => {
            let style = this.getStyle(k.booking_status)
            let diff = k.check_out - k.check_in
            diff /= ( 24 * 3600 )
            style.width = diff * parseFloat(this.width) + 'vw'

            let diff_2 = k.check_in - new Date(this.filter.dates[0]).getTime() / 1000 
            diff_2 /= ( 24 * 3600 )
            style.left = diff_2 * parseFloat(this.width) + 'vw'
            k.style = style
          })
        })
      })
    },
    onToggle(idx) {
      if (this.panels.includes(idx)) {
        this.panels = this.panels.filter((el) => el != idx);
      } else {
        this.panels.push(idx);
      }
    },
    getStyle(status){
      let my_style = {}
      if (status === 'reserved') {
        my_style = {
          background: 'orange',
          color: 'black',
          'font-weight': 'bold',
        }
      } else if (status === 'booked') {
        my_style = {
          background: 'yellow',
          color: '#171717',
          'font-weight': 'bold'
        }
      } else if (status === 'check_in') {
        my_style = {
          background: 'rgb(22, 221, 22)',
          color: 'black',
          'font-weight': 'bold',
        }
      } else if (status === 'block') {
        my_style = {
          'z-index': 1,
          background: 'rgba(79, 0, 255, .5)',
        }
      } else if (status == 'time') {
        my_style = {
          background: 'rgba(255, 0, 0, 0.5)',
          'font-size': '14px',
        }
      } else if (status == 'koika') {
        my_style = {
          background: 'deeppink',
          'font-size': '14px',
        }
      } else if (status === 'canceled' || status === 'client_cancel' || status === 'company_cancel' || status === 'expired' || status === 'check_out' || status === 'repaired') {
        my_style = {
          background: 'lightgray',
          color: 'black',
          'font-weight': 'bold',
        }
      }
      return my_style
    },
    openBooking(item, category, room){
      item.category = { ...category }
      item.room = { ...room }
      item.room_list = this.categories.find(e => e.id == category.id).room_list
      item.date = `${this.$moment(new Date(item.check_in * 1000)).format('DD-MM-YYYY')} - ${this.$moment(new Date(item.check_out * 1000)).format('DD-MM-YYYY')}`
      this.bookingItem = item
      this.bookingModal = true
    },
    async saveBooking(){
      if(!this.bookingItem.is_my) {
        this.$store.commit("ui/SET_NOTIFICATION", {
          show: true,
          message: "Нельзя поменять бронь!",
          color: "error",
        });
        return;
      }
      let res = await dynamicService.changeBooking({
        booking_id: this.bookingItem.id, 
        room_id: this.bookingItem.room
      })
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно изменилось!",
        color: "success",
      });
      this.bookingModal = false
      this.bookingItem = {}
      this.search()
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_wrapper {
    padding: 40px 30px;
    background: #fff;
    &__title {
        text-align: left;

        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #52B1BA;
        margin-bottom: 40px;
    }
    &__subtitle {
        text-align: center;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
        margin-bottom: 24px;
    }
    .boxes {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        align-items: center;
        div {
            max-width: 193px;
            height: 120px;
            background: #FFFFFF;
            border: 1px solid #F5F5F5;
            box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.1);
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            p {
                font-family: 'Gilroy', sans-serif;
                margin-top: 16px;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 25px;
            }
        }
    }
    .button_wrapper {
        padding-top: 25px;
        text-align: center;
    }
}
.search_bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 160px;
  align-items: center;
}
.sales_title {
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: #021011;
  margin-bottom: 24px;
}
.calendar_wrapper {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 24px;
  overflow-x: auto;
  p {
    margin-bottom: 0;
  }
  &__header {
    display: grid;
    grid-template-columns: 163px 1fr;
    align-items: start;
    &__title {
      height: 86px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 20px;
      border-right: 1px solid #e3e3e3;
      p {
        font-size: 16px;
        line-height: 19px;
        color: #52b1ba;
      }
    }
    &__days {
      &__month {
        height: 46px;
        display: flex;
        align-items: flex-end;
        padding-bottom: 7px;
        padding-left: 7px;
        justify-content: flex-start;
        p {
          font-size: 16px;
          line-height: 19px;
          color: #ff8413;
          text-transform: capitalize;
        }
      }
    }
  }
}
.header_dates {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(90, 1fr);
  .single_box {
    border-right: 1px solid #e3e3e3;
    border-top: 1px solid #e3e3e3;
    background: #fff;
    min-width: 26px;
    height: 40px;
    text-align: center;
    font-size: 12px;
    line-height: 15px;
    color: #021011;
    display: flex;
    align-items: center;
    justify-content: center;
    &:last-of-type {
      border-right: unset !important;
    }
  }
}
.calendar_body {
  width: 100%;
  .room_headers {
    display: grid;
    grid-template-columns: 162px 1fr;
    grid-column-gap: 1px;
    align-items: start;
    background: #e3e3e3;
    &__title {
    z-index: 3;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: #f4f4f4;
      height: 100%;
      width: 100%;
      padding-left: 19px;
      font-size: 16px;
      line-height: 19px;
      color: #021011;
    }
  }
}
.panel_header {
  min-height: unset !important;
  .header_dates {
    .single_box {
      border-top: 1px solid #e3e3e3;
      background: #f4f4f4;
    }
  }
}
.panel_content {
  .room_headers {
    background: #e3e3e3;
    &__title {
      background: #fff;
      border-top: 1px solid #e3e3e3;
    }
  }
  .header_dates {
    background: #fff;
    .single_box {
      border-right: 1px solid #e3e3e3;
      border-top: 1px solid #e3e3e3;
      background: #fff;
      min-width: 26px;
      height: 40px;
      text-align: center;
      font-size: 12px;
      line-height: 15px;
      color: #021011;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      &:last-of-type {
        border-right: unset !important;
      }
      &__status {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background: red;
      }
    }
    .single_box_booking{
      position: absolute;
      min-width: 0px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      overflow: hidden;
      will-change: contents;
      border: 1px solid lightgrey;
      z-index: 2;
      span{
        width: 100%;
        display: -webkit-box;
        overflow: hidden;
        /* height: 15px; */
        -webkit-line-clamp: 1;
        word-break: break-all;
        -webkit-box-orient: vertical;
        text-align: left;
      }
      div{
        display: flex;
        align-items: flex-start;
        height: 100%;
        gap: 2px;
        width: 100%;
        flex-direction: column;
      }
      span:nth-child(2){
        width: 50%;
      }
    }
  }
}
.room_headers_title__cat {
  border-top: 1px solid #e3e3e3 !important;
}
.isWeekEnd {
  background: rgba($color: #ff8413, $alpha: 0.1) !important;
}
.widthStatus {
  background: linear-gradient(to left, #fff 50%, #ff8413 50%);
}
.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(0, 0, 0, 0) !important;
}
.v-expansion-panel::before {
  box-shadow: unset !important;
}
</style>
